<template>
  <div>
    <span class="text-caption font-weight-bold" v-text="lab" />
    <br />
    <v-tooltip right>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          text
          icon
          x-small
          color=""
          @click.prevent="fileDownload"
        >
          <v-icon> mdi-download </v-icon>
        </v-btn>
      </template>
      <span v-text="'Descargar'" />
    </v-tooltip>
  </div>
</template>

<script>
import { dateTimeToFile, base64ToArrayBuffer } from "../control";

export default {
  props: ["lab", "b64", "ext"],
  methods: {
    fileDownload() {
      const blob = new Blob([base64ToArrayBuffer(this.b64)], {
        type: "application/" + this.ext,
      });
      let downloadLink = document.createElement("a");
      downloadLink.setAttribute("target", "_blank");
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = "SM_" + dateTimeToFile() + "." + this.ext;
      downloadLink.click();
    },
  },
};
</script>